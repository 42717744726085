.container {
  width: 125px;
}

.ant-upload.ant-upload-select {
  margin-bottom: 0px !important;
}

.ant-upload-list-item-container {
  margin-bottom: 0px !important;
}
