body {
  /* padding-top: 55px; */
}
.hide-resize-observer {
  display: none !important;
}
#Footer {
  display: none;
}
#Newsroom .container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
#Newsroom .navbar .container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}