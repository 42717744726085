@tailwind base;
@tailwind components;
@tailwind utilities;


.ant-drawer-body ol, ul, menu {
    list-style: unset;
    margin: revert;
    padding: revert;
}

html {
    font-size: 100% !important;
}

.navbar-fixed-top, .navbar-fixed-bottom {
    z-index: 999 !important;
}

.ant-btn-primary {
  background-color: #1677ff !important;
  color: #fff !important;
}

.ant-layout-content .leading-3 {
  line-height: inherit;
}

.ant-layout-content .open-close-button {
  position: relative;
  right: 25px;

}

.container {
  max-width: 100%;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

pre:has(div.codeblock) {
  padding: 0;
}

/* ::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html {
  background: #202123;
}

@media (max-width: 720px) {
  pre {
    width: calc(100vw - 110px);
  }
}

pre:has(div.codeblock) {
  padding: 0;
} */
/* :root {
    background-color: #dddddd;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }
  
  .chat-wrapper {
    max-width: 40rem;
    margin: 0 auto 10rem auto;
    padding: 0 2rem;
    font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
      Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial;
  }
  
  .empty {
    padding: 2rem 0;
    color: #444444;
  }
  
  .message-wrapper {
    border-top: 1px solid rgb(158, 174, 190);
    padding-top: 1rem;
    margin-top: 1rem;
  }
  
  .message-wrapper:first-child {
    border-top: none;
  }
  
  .message-wrapper pre {
    font-size: 1rem;
    font-family: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
      Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial;
  }
  
  .chat-message {
    white-space: pre-wrap;
  }
  
  .role {
    font-size: 0.8rem;
  }
  
  .tag-wrapper {
    display: flex;
  }
  
  .tag {
    margin: 0 1rem;
    font-size: 0.8rem;
  }
  
  .tag:first-child {
    margin-left: 0;
  }
  
  .prompt-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 1rem 0;
    background-color: #dddddd;
  }
  
  .prompt-wrapper > div {
    display: flex;
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
  }
  
  .prompt-wrapper textarea {
    width: 100%;
    margin-right: 1rem;
    padding: 1rem;
    font-size: 1rem;
    resize: none;
    margin-left: 2rem;
  }
  
  .prompt-wrapper button {
    padding: 0 1rem;
    margin-right: 2rem;
  } */