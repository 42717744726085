@import "~antd/dist/reset.css";

.ant-table table {
  border-collapse: collapse;
}

button:focus {
  outline: 0;
}
.Timeline {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
  /* overflow: hidden; */
}

/* .Timeline input {
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
} */

.Timeline-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.Timeline-header {
  background-color: #222;
  height: 30px;
  padding: 20px;
  color: white;
}
.Timeline-footer {
  background-color: #222;
  height: 30px;
  padding: 20px;
  color: white;
}

.Timeline-header-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: "Rajdhani", sans-serif;
}

.Timeline-intro {
  font-size: large;
}

@keyframes Timeline-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Timeline-Container {
  display: flex;
  flex: 10;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px;
  margin-top: 0px;
  overflow: hidden;
}

.Timeline-Bottom-tray {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px;
  padding: 20px;
  border: 2px solid gray;
  border-radius: 10px;
}

.Timeline-Left-Side-Panel {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: 2px solid gray;
  border-radius: 10px;
  margin: 10px;
  flex-direction: column;
  height: 89%;
  /* overflow: scroll; */
}
.Timeline-Right-Side-Panel {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 10px;
  margin: 10px;
  flex-direction: column;
  height: 89%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.Filter-button {
  padding: 10px;
  border: 2px solid gray;
  border-radius: 10px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  font-size: 12px;
  width: 100%;
  background-color: white;
}
.Filter-button:hover {
  border: 3px solid gray;
}
.Filter-button-selected {
  padding: 10px;
  border: 2px solid gray;
  border-radius: 10px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  font-size: 12px;
  width: 100%;
  background-color: lightgoldenrodyellow;
}
.Filter-button-selected:hover {
  border: 3px solid gray;
}
.filter-list {
  flex: 4;
  /* height: 800px; */
  height: 89%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px solid gray;
  border-radius: 10px;
  margin: 10px;
  overflow: scroll;
}
.Search-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin: 10px;
}
.Search-button {
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid gray;
  border-radius: 10px;
  /* margin-top: 10px;
    margin-bottom: 10px; */
  font-size: 12px;
  width: 100%;
  background-color: white;
}
.Search-button:hover {
  border: 3px solid gray;
}
.Search-button-selected {
  padding: 5px;
  border: 2px solid gray;
  border-radius: 10px;
  /* margin-top: 10px;
    margin-bottom: 10px; */
  font-size: 12px;
  width: 100%;
  background-color: lightcyan;
}
.Search-button-selected:hover {
  border: 3px solid gray;
}

@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v54/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

input {
  padding: 10px;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.Clear-button {
  padding: 10px;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

/* .w-md-editor-text-pre > code,
.w-md-editor-text-input {
  font-size: 23px !important;
  line-height: 24px !important;
} */

ul {
  margin: 0;
}

li {
  list-style: none;
  border: 1px solid #ccc;
  margin-top: -1px;
  line-height: 2;
  padding: 5px 20px;
  background: white;
  color: black;
}
